<template>
  <div class="BigTable">
    <div class="TableContentArea">
      <table class="tablehead">
        <tr class="headtr" :style="{ height : trHeight }">
          <th class="thSN">
            <div :style="{ width: SnWidth }"></div>
          </th>
          <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click="ChangeSort(value1.fieldsNameForVali)">
            <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
          </th>
        </tr>
      </table>
      <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
        <table class="tablebody">
          <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" @dblclick="sendBackData(index2)">
            <td>
              <div class="tdSN" :style="{ width: SnWidth }">{{ index2 + 1 + searchElement.LinesPerPage * (searchElement.Page - 1) }}</div>
            </td>
            <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
              <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
            </td>
          </tr>
        </table>
      </div>
      <table class="tablefoot">
        <tr class="foottr" :style="{ height : trHeight }">
          <td>
            <div class="footsum" :style="{ width: '3.75rem' }">合计</div>
          </td>
          <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
            <div :style="{ width: value4.showLen  + 'rem' }"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="MainBodyTablePage" :style="{ width: '100%'}">
      <!--页数模块 -->
      <div class="PageCountContainer">
        <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
        <span class="PagerEveryPageText">每页/条</span>
        <div class="EditBlock">
          <input class="LinesPerPageChooese" type="text" v-model="searchElement.LinesPerPage" />
          <div class="LinesPerPageChooseButton"></div>
        </div>
        <div class="GoFirstPageButton" @click="FirstPage()"></div>
        <div class="GoLastPageButton" @click="DownPage()"></div>
        <span class="pagetextforDi">第</span>
        <div class="EditBlock">
          <input class="PageEdit" type="text" v-model="searchElement.Page" />
          <div class="PageChooseButton"></div>
        </div>
        <span class="pagetextforYe">页</span>
        <div class="GoNextPageButton" @click="UpPage()"></div>
        <div class="GoEndPageButton" @click="EndPage()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { useStore } from "vuex";
import { ChangeFirToLo, ChangeFirToUp, dataFilter, ChangeYMD, changeArrContentToDate, copyArray } from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { checkTtCondi, transferToCode } from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  props: ['sendBackFildsName', 'tableTile'],
  setup(props, { emit }) {

    //#region 数据定义区
    const store = useStore();
    const sendBackFildsName = props.sendBackFildsName.sendBackName
    let docuCenterDate = inject('searchItemList')
    let tableBodyElement = inject('searchItemList2')

    let tableHead = reactive([]);
    let tableBody = reactive([]);
    let regArr = [];
    let SearchContent = reactive({})
    let totalLines = ref(0)
    let totalPage = ref(1)
    let searchElement = store.state.searchEle;
    let deleteKey = ref('');
    const tableTitle = props.tableTile

    let tableCode = store.state.loginInitializeData.authList.viewLists.filter(x => x.viewId == tableBodyElement.tableName)[0].viewCode
    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + 20 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });
    //尺寸控制
    let SnWidth = ref('3.75rem')
    let trHeight = ref('1.875rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    //#endregion

    //#region 公式定义区



    const fillSearchContent = () => {
      for (let key in tableBodyElement) {
        SearchContent[key] = tableBodyElement[key].defaultValue
      }
      if (docuCenterDate.tableName == 'RoList' && SearchContent?.InspectedFS) {
        SearchContent.InspectedFS = 1
      }
    }

    const fillDeleteKey = () => {
      for (let key in tableBodyElement) {
        if (tableBodyElement[key].deleteId == true) {
          deleteKey.value = ChangeFirToLo(key)
        }
      }
    }
    //h给regArr以及tableHead赋值
    const refreshTableHeadandregArr = () => {
      for (let item of store.state.loginInitializeData.listForValidations) {
        if (
          item.tableNameForVali == tableBodyElement.tableName &&
          item.validateMethod == "add" &&
          item.showInTable == true
        ) {
          regArr.push(item.fieldsNameForVali.toLowerCase());
          let tem = {};
          tem.showName = item.showName;
          tem.fieldsNameForVali = ChangeFirToLo(item.fieldsNameForVali);
          tem.showLen = item.showLen;
          tableHead.push(tem);
        }
      }
    }
    //清空regArr以及tableHead
    const clearTableHeadandregArr = () => {
      regArr.splice(0)
      tableHead.splice(0)
    }

    //清空vueX中的数据
    const clearVueXPage = () => {
      store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 200 })
      store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
      store.commit('modifysearchEle', { keyName: 'SortFieldsName', valueName: '' })
      store.commit('modifysearchEle', { keyName: 'SortDirection', valueName: -1 })
    }
    //给搜索元素初始化
    let SortFieldsName = ''
    const initializeVueXPageDate = () => {
      for (let key in tableBodyElement) {
        if (key.indexOf('Date') > -1 && searchElement.SortFieldsName != '') {
          store.commit('modifysearchEle', { keyName: 'SortFieldsName', valueName: key })
          SortFieldsName = key
          return;
        }
      }

      for (let key in tableBodyElement) {
        if (key == 'tableName' || key == 'editName' || key == 'addName' || key == 'fileModelName' || key == 'tableCName' || key == 'pageNameManageCode' || key == 'typeJudge') {
          continue;
        }
        store.commit('modifysearchEle', { keyName: 'SortFieldsName', valueName: key })
        SortFieldsName = key
        return;
      }
    }

    const acceptDocuData = () => {
      for (let key in docuCenterDate.documentContent) {
        for (let key2 in SearchContent) {
          if (key == 'PartBCompanyId' && key2 == 'PartBCompanyAbbre') {
            SearchContent[key2] = docuCenterDate.documentContent[key]
          }
        }
      }
    }
    acceptDocuData()
    //f搜索数据给表身赋值
    const searchList = () => {
      let b = false
      for (let key in tableBodyElement) {
        if (store.state.searchEle.SortFieldsName == key) {
          b = true
        }
      }
      if (!b) {
        initializeVueXPageDate()
        fillSearchContent()
      }
      let addUrl = '';

      switch (tableBodyElement.typeJudge) {
        case 'Iw':
          addUrl = '/' + tableBodyElement.tableName + '/GetListByMulSearchInAuth';
          break;
        case 'Ow':
          addUrl = '/' + tableBodyElement.tableName + '/GetListByMulSearchOutAuth';
          break;
        default:
          addUrl = '/' + tableBodyElement.tableName + '/GetListByMulSearch';
      }
      if (tableTitle == 'ST' && tableBodyElement.typeJudge == 'Iw') {
        addUrl = '/' + tableBodyElement.tableName + '/GetListByMulSearch';
      }

      fillSearchContent()
      let config = {
        method: "post",
        url: addUrl,
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          if (tableBodyElement.tableName == 'BusinessPartnerList') {
            const length = res.businessPartnerListMultiple?.length
            if (length > 0) {

              res.businessPartnerListMultiple.sort(function (a, b) { return b.weight - a.weight })
              if (tableTitle == 'TT') {
                for (let i = length - 1; i >= 0; i--) {
                  const compnyId = transferToCode(res.businessPartnerListMultiple[i].partBCompanyAbbre, 'PartBCompanyId')
                  if (compnyId >= 20000000) {

                    if (res.businessPartnerListMultiple.length > 0) {
                      res.businessPartnerListMultiple.splice(i, 1)
                    }
                  }
                  else {
                    checkTtCondi(compnyId).then(res2 => {
                      if (res2 != 'pass') {
                        for (let j = tableBody.length - 1; j >= 0; j--) {
                          if (transferToCode(tableBody[j].partBCompanyId, 'PartBCompanyId') == compnyId) {
                            tableBody.splice(j, 1)
                          }
                        }
                      }
                    })
                  }
                }
              }

              if (tableTitle == 'PO' || tableTitle == 'SO' || tableTitle == 'AS' ||
                tableTitle == 'OB' || tableTitle == 'MP' || tableTitle == 'FR') {
                for (let i = length - 1; i >= 0; i--) {

                  // if (res.businessPartnerListMultiple[i]?.partBCompanyAbbre.indexOf('小懒-') > -1) {
                  //   res.businessPartnerListMultiple.splice(i, 1)
                  //   continue
                  // }
                  if (tableTitle == 'AS' || tableTitle == 'OB') {
                    if (!res.businessPartnerListMultiple[i]?.isOnLine) {
                      res.businessPartnerListMultiple.splice(i, 1)
                      continue
                    }
                  }
                  if (tableTitle == 'MP' || tableTitle == 'FR') {
                    if (res.businessPartnerListMultiple[i]?.partBCompanyAbbre.indexOf('青屠科技') > -1) {
                      res.businessPartnerListMultiple.splice(i, 1)
                      continue
                    }
                  }
                }
              }
            }
          }
          if (tableBodyElement.tableName == 'StaffList') {
            const length1 = res.staffListMultiple?.length
            const userName = store.state.loginInitializeData.userName
            if (userName != '管理员') {
              for (let i = length1 - 1; i >= 0; i--) {
                if (res.staffListMultiple[i].staffName == '管理员') {
                  res.staffListMultiple.splice(i)
                }
              }
            }
          }
          let name = ChangeFirToLo(tableBodyElement.tableName) + 'Multiple';
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res[name]);
          dataFilter(tableBody, tableBodyElement, deleteKey.value)
          changeArrContentToDate(tableBody)
          totalLines.value = res.lineCounts;
          totalPage.value = res.totalPage;

        })
        .catch((err) => {
        });
    };
    //f将页数调整为1
    let turnPageToOne = () => {
      store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
      searchList()
    }
    //f增加页数
    let UpPage = () => {
      if (searchElement.Page < totalPage.value) {
        store.commit('modifysearchEle', { keyName: 'Page', valueName: searchElement.Page + 1 })
        searchList()
      }
    };
    //f跳转最后一页

    let EndPage = () => {
      store.commit('modifysearchEle', { keyName: 'Page', valueName: totalPage.value })
      searchList()
    };
    //f减少页数
    let DownPage = () => {
      if (searchElement.Page > 1) {
        store.commit('modifysearchEle', { keyName: 'Page', valueName: searchElement.Page - 1 })
        searchList()
      }
    };
    //f跳转到第一页
    let FirstPage = () => {
      store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
      searchList()
    };
    //f改变排序
    let ChangeSort = (fildsname) => {
      store.commit('modifysearchEle', { keyName: 'SortFieldsName', valueName: ChangeFirToUp(fildsname) })
      store.commit('modifysearchEle', { keyName: 'SortDirection', valueName: -searchElement.SortDirection })
      searchList()
    };

    //单个删除的方法

    const refresh = () => {
      searchList()
    }

    const sendBackData = (index) => {
      if (sendBackFildsName == 'repairRecordCode' || sendBackFildsName == 'obTracingCode') {
        emit('getPopValue', tableBody[index])
      }
      else {
        emit('getPopValue', tableBody[index][sendBackFildsName])
      }

      if (tableTitle == 'SO' || tableTitle == 'PO' || tableTitle == 'RR' || tableTitle == 'PR') {
        emit('getResStaffId', tableBody[index])
      }

    }
    //#endregion

    //#region 数据提供区
    //#endregion

    //#region 挂载前加载

    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 离开时处理
    onUnmounted(() => {
      emit('clearData')
    })
    //#endregion
    watch(
      () => [searchElement.Page, searchElement.SortFieldsName, searchElement.SortDirection],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (totalPage.value > 0) {
            if (parseInt(newValue[0]) > totalPage.value) {
              // ElMessage('不能超过最大页数');
              store.commit('modifysearchEle', { keyName: 'Page', valueName: totalPage.value })
              return;
            }
          }
          if (parseInt(newValue[0]) < 1) {
            // ElMessage('页数不能小于1');
            store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
            return;
          }
        }
      }
    );
    //w用于监控页数，确保客户不乱填
    watch(
      () => searchElement.LinesPerPage,
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (newValue > 100) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 100 })
            ElMessage("每页数量不能超过100");
          }
          if (newValue < 1) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 1 })
            ElMessage("每页数量不能低于1");
          }
        }
      }
    );

    //#endregion


    onMounted(() => {
      refreshTableHeadandregArr()
      initializeVueXPageDate()
      fillSearchContent()
      fillDeleteKey()
      searchList()
    })
    onUnmounted(() => {
      clearTableHeadandregArr()
      clearVueXPage()
    })
    return {
      SearchContent,
      fillSearchContent,
      tableHead,
      tableBody,
      tablewidth,
      searchList,
      totalLines,
      totalPage,
      ChangeSort,
      UpPage,
      EndPage,
      FirstPage,
      DownPage,
      turnPageToOne,
      searchElement,
      deleteKey,
      //尺寸部分
      SnWidth,
      trHeight,
      tbodyHeight,
      refresh,
      sendBackData
    }
  }
}
</script>

<style scoped lang="less">
@pageHeight: 1.875rem;
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>