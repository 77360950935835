<template>
  <div class="BarTotalBox">
    <div class="funcBigBox">
      <div class="BarTitle">操作区</div>
      <div class="funcBox">
        <div class="funcBoxLayControl">
          <div class="timesForPrint">
            <label>打印次数: </label><input class="timesForPrint" type="number" v-model="timesPrint" @blur="checkNumber()">
          </div>
          <div class="commodityMangeLayer">
            <input class="commInput" v-model="inputValue" @input="insertSearch" placeholder="请输入搜索内容" />
          </div>
          <div class="CommodityContainer" v-show="searchResult.length != 0">
            <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" :key="searchitem.commodityId + 'printSearch'" @mousedown.prevent="setToPrint(searchIndex)"><input class="comminputDown" v-model="searchitem.commodityName" type="text" :style="[{height:'1.625rem'}]"><input type="text" class="searchIndication" v-model="searchitem.show"></div>
            <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
          </div>
        </div>
      </div>
    </div>
    <div class="GapArea"></div>
    <div class="StorageBigBox">
      <div class="BarTitle">打印区</div>
      <div class="BarTopButtonBox">
        <div class="topButton" v-print="printAll">打印</div>
        <div class="topButton long" @click="showPrice()">显示/隐藏价格</div>
        <div class="topButton" @click="transferAllToStore()">全部转移</div>
        <div class="topButton redButton" @click="deleteAllPrint()">全部删除</div>
      </div>
      <div class="BarShowBox">
        <div class="totalMange">
          <div class="printAreaReal" id="printAll">
            <div class="printBoxForOne" v-for="(item,index1) in toBePrint" :key="item.barcode + 'printArea'">
              <div class="partNameContainer" v-show="item.partName && !item.docType" :style="{ marginTop: '20px' }">{{item.partName}}</div>
              <div class="docTypeContainer" v-show="item.docType" :style="{ marginTop: '3px' }">{{item.docType}}</div>
              <div class="companyNameContainer" v-show="item.companyName">公司名称: {{item.companyName}}</div>
              <div class="docDateContainer" v-show="item.docDate">单据时间: {{item.docDate}}</div>
              <div class="remarkContainer" v-show="item.machineName && item.docType">机器型号: {{item.machineName}}</div>
              <div class="remarkContainer" v-show="item.breakdownCause && item.docType">故障描述: {{item.breakdownCause}}</div>
              <div class="remarkContainer" v-show="item.partName && item.docType">商品名称: {{item.partName}}</div>
              <div class="salesPriceContainer" v-show="priceShow && item.salesPrice">销售价格: {{item.salesPrice}}</div>
              <!-- <div class="remarkContainer" v-show="item.warranty">质保天数: <input class="warrentyDayInput" type="text" v-model="item.warranty" @blur="checkWarranty($event,index1,'print')">{{item.warrantyRemark}}</div> -->
              <div class="remarkContainer" v-show="item.warranty">质保天数: <input class="warrentyDayInput" type="text" v-model="item.warranty" @blur="checkWarranty($event,index1,'print')"><input class="warrentyDayInput" type="text" v-model="item.warrantyRemark"></div>
              <div class="remarkContainer" v-show="item.remark">备注说明: {{item.remark}}</div>
              <div class="barcodeContainer" :style="{marginTop:item.docType ? '0rem' : '2rem'}">
                <Barcode :codevalue="item.barcode" :idvalue="item.id"></Barcode>
              </div>
            </div>
          </div>
          <div class="funcAreaReal">
            <div class="printFuncBoxForOne" v-for="(item,index) in toBePrint" :key="item.barcode + 'funcArea'">
              <div class="printAreaButton icon bigword" @click="transferToStorage(index)"></div>
              <div class="printAreaButton redButton" @click="deletePrintOne(index)">删除</div>
              <div class="printAreaButton" @click="duplicatePrintOne(index)">复制</div>
            </div>
          </div>
        </div>
      </div>
      <div class="BarBomtomButtonBox">
        总数:{{printTotal}}
      </div>
    </div>
    <div class="GapArea"></div>
    <div class="PrintBigBox">
      <div class="BarTitle">留存区</div>
      <div class="BarTopButtonBox">
        <div class="topButton long" @click="showPrice2()">显示/隐藏价格</div>
        <div class="topButton" @click="transferAllToPrint()">全部转移</div>
        <div class="topButton redButton" @click="deleteAllStore()">全部删除</div>
      </div>
      <div class="BarShowBox">
        <div class="totalMange">
          <div class="funcAreaReal">
            <div class="printFuncBoxForOne" v-for="(item,index) in toBeStore" :key="item.barcode + 'funcArea'">
              <div class="printAreaButton icon bigword" @click="transferToPrint(index)"></div>
              <div class="printAreaButton redButton" @click="deleteStoreOne(index)">删除</div>
            </div>
          </div>
          <div class="printAreaReal">
            <div class="printBoxForOne" v-for="(item,index2) in toBeStore" :key="item.barcode + 'printArea'">
              <div class="partNameContainer" v-show="item.partName && !item.docType" :style="{ marginTop: '20px' }">{{item.partName}}</div>
              <div class="docTypeContainer" v-show="item.docType" :style="{ marginTop: '3px' }">{{item.docType}}</div>
              <div class="companyNameContainer" v-show="item.companyName">公司名称: {{item.companyName}}</div>
              <div class="docDateContainer" v-show="item.docDate">单据时间: {{item.docDate}}</div>
              <div class="remarkContainer" v-show="item.machineName && item.docType">机器型号: {{item.machineName}}</div>
              <div class="remarkContainer" v-show="item.breakdownCause && item.docType">故障描述: {{item.breakdownCause}}</div>
              <div class="remarkContainer" v-show="item.partName && item.docType">商品名称: {{item.partName}}</div>
              <div class="salesPriceContainer" v-show="priceShow2 && item.salesPrice">销售价格: {{item.salesPrice}}</div>
              <div class="remarkContainer" v-show="item.warranty">质保天数: <input class="warrentyDayInput" type="text" v-model="item.warranty" @blur="checkWarranty($event,index2,'store')">{{item.warrantyRemark}}</div>
              <div class="remarkContainer" v-show="item.remark">备注说明: {{item.remark}}</div>
              <div class="barcodeContainer" :style="{marginTop:item.docType ? '0rem' : '2rem'}">
                <Barcode :codevalue="item.barcode" :idvalue="item.id"></Barcode>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="BarBomtomButtonBox">
        总数:{{storeTotal}}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onUnmounted, reactive, ref, toRefs, watch } from 'vue-demi'
import Barcode from '@/components/Common/ElementUI/Barcode'
import { copyObj, getUUId, insertSearchOrigin } from '@/hooks/tools'
import _ from "lodash";
import { changeToViewCode, getRetailPriceById } from '@/hooks/OpenOrder'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    Barcode
  },
  setup() {

    const store = useStore()
    const storage = window.localStorage;

    let toBeStore = reactive([

    ])

    let toBePrint = reactive([

    ])

    const changeId = (id) => {
      if (id.substring(0, 5) == 'print') {
        return 'sotre' + id.substring(5)
      }
      else {
        return 'print' + id.substring(5)
      }
    }

    const transferToPrint = (index) => {
      let body = copyObj(toBeStore[index])
      body.id = changeId(body.id)
      toBeStore.splice(index, 1)
      toBePrint.unshift(body)
      store.commit('updateBarcodeCountDefi', toBeStore.length)
    }

    const transferToStorage = (index) => {
      let body = copyObj(toBePrint[index])
      body.id = changeId(body.id)
      toBePrint.splice(index, 1)
      toBeStore.unshift(body)
      store.commit('updateBarcodeCountDefi', toBeStore.length)
    }

    const duplicatePrintOne = (index) => {
      let body = copyObj(toBePrint[index])

      toBePrint.splice(index, 0, body)

    }

    const deletePrintOne = (index) => {
      toBePrint.splice(index, 1)
    }

    const deleteStoreOne = (index) => {
      toBeStore.splice(index, 1)
      store.commit('updateBarcodeCountDefi', toBeStore.length)
    }


    const printForAll = reactive({
      // 浏览器打印的对象
      printAll: {
        id: 'printAll',
        closeCallback(vue) {
          let b = confirm('打印成功并清除打印区')
          if (!b) {
            return;
          }
          else {
            for (let item of toBePrint) {
              if (item.docType == '质保单') {
                storage.setItem('warranty' + store.state.loginInitializeData.companyId + item.commodityId.substring(8, 12), item.warranty)
                storage.setItem('warrantyRemark' + store.state.loginInitializeData.companyId + item.commodityId.substring(8, 12), item.warrantyRemark)
              }
            }
            toBePrint.splice(0)
          }
        }
      },
    });

    let priceShow = ref(false)

    const showPrice = () => {
      priceShow.value = !priceShow.value
    }

    let priceShow2 = ref(false)

    const showPrice2 = () => {
      priceShow2.value = !priceShow2.value
    }

    const transferAllToPrint = () => {
      const length = toBeStore.length
      if (!length) {
        ElMessage('无条码可以转移')
        return;
      }
      for (let i = length - 1; i >= 0; i--) {
        transferToPrint(i)
      }
      store.commit('updateBarcodeCountDefi', toBeStore.length)
    }

    const transferAllToStore = () => {
      const length = toBePrint.length
      if (!length) {
        ElMessage('无条码可以转移')
        return;
      }
      for (let i = length - 1; i >= 0; i--) {
        transferToStorage(i)
      }
      store.commit('updateBarcodeCountDefi', toBeStore.length)
    }

    let printTotal = computed(() => {
      return toBePrint.length
    })

    let storeTotal = computed(() => {
      return toBeStore.length
    })

    let inputValue = ref('')

    let searchResult = reactive([]);

    const insertSearch = _.debounce((e) => {
      insertSearchOrigin(e, searchResult)
    }, 500)

    let timesPrint = ref(1)

    const checkNumber = () => {
      if (timesPrint.value < 1 || timesPrint.value > 100) {
        ElMessage('打印数量在1-100之间')
        timesPrint.value = 1
        return
      }
      timesPrint.value = timesPrint.value.toFixed(0)
    }

    const setToPrint = (index) => {
      const newId = getUUId()
      getRetailPriceById(searchResult[index].commodityId).then(res => {
        let b = {
          id: 'print' + newId,
          partName: searchResult[index].commodityName,
          docType: '',
          companyName: '',
          docDate: '',
          salesPrice: res,
          remark: '',
          barcode: searchResult[index].barcode
        }
        for (let i = 0; i < timesPrint.value; i++) {
          toBePrint.unshift(b)
        }
        clearSearchResult()
        inputValue.value = ''
      })
    }

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    const deleteAllPrint = () => {
      toBePrint.splice(0)
    }

    const deleteAllStore = () => {
      toBeStore.splice(0)
      store.commit('updateBarcodeCountDefi', 0)
    }



    const user = store.state.loginInitializeData.userId

    let activePage = ref(false)

    const startUpAction = () => {
      let printListFromWin = []
      if (JSON.parse(storage.getItem('BarPrintList' + user))) {
        printListFromWin = JSON.parse(storage.getItem('BarPrintList' + user));
      }

      let storeListFromWin = []

      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
      }

      let priceShowFromWin = false;

      if (JSON.parse(storage.getItem('PriceShow' + user))) {
        priceShowFromWin = JSON.parse(storage.getItem('PriceShow' + user));
      }

      let priceShowFromWin2 = false;

      if (JSON.parse(storage.getItem('PriceShow2' + user))) {
        priceShowFromWin2 = JSON.parse(storage.getItem('PriceShow2' + user));
      }

      toBePrint.splice(0)

      toBeStore.splice(0)

      for (let item of printListFromWin) {
        toBePrint.push(item)
      }

      for (let item of storeListFromWin) {
        toBeStore.push(item)
      }

      priceShow.value = priceShowFromWin

      priceShow2.value = priceShowFromWin2

      activePage.value = true

      openRefresh()

    }

    const closeDownAction = () => {
      storage.setItem('BarPrintList' + user, JSON.stringify(toBePrint))

      storage.setItem('BarStoreList' + user, JSON.stringify(toBeStore))

      storage.setItem('PriceShow' + user, JSON.stringify(priceShow.value))

      storage.setItem('PriceShow2' + user, JSON.stringify(priceShow2.value))

      store.commit('updateBarcodeCount')
    }

    let id;

    const openRefresh = () => {
      if (!id) {
        id = setInterval(closeDownAction, 5000)
      }
    }

    const closeRefresh = () => {
      clearInterval(id)
    }

    const code = changeToViewCode('BanCodePrint')

    startUpAction()
    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          startUpAction()
        }
        else {
          if (activePage) {
            closeDownAction()
            activePage.value = false
            closeRefresh()
          }
        }
      }
    )

    onUnmounted(() => {
      closeDownAction()
      activePage.value = false
      closeRefresh()
    })

    const checkWarranty = (e, index, type) => {
      if (!e.target.value) {
        if (type == 'store') {
          toBeStore[index].warranty = '0天'
        }
        else {
          toBePrint[index].warranty = '0天'
        }
      }
    }


    return {
      toBePrint, checkWarranty,
      priceShow,
      toBeStore,
      transferToPrint,
      transferToStorage,
      deletePrintOne,
      deleteStoreOne,
      ...toRefs(printForAll),
      showPrice,
      priceShow2,
      showPrice2,
      transferAllToPrint,
      transferAllToStore,
      printTotal,
      storeTotal,
      duplicatePrintOne,
      inputValue,
      insertSearch,
      searchResult,
      setToPrint,
      clearSearchResult,
      timesPrint,
      checkNumber,
      deleteAllPrint,
      deleteAllStore
    }
  }
}
</script>

<style scoped lang="less">
.BarTotalBox {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.GapArea,
.funcBigBox,
.StorageBigBox,
.PrintBigBox {
  margin-top: 0.5rem;
}

.GapArea {
  width: 5%;
}
.funcBigBox {
  width: 29.5%;
}

.StorageBigBox {
  width: 29.5%;
}
.PrintBigBox {
  width: 29.5%;
}
.BarTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.funcBox {
  border: 1px solid #c9c9c9;
  height: 90%;
}
.BarTopButtonBox {
  border: 1px solid #c9c9c9;
  height: 5%;
  display: flex;
}
.BarShowBox {
  border: 1px solid #c9c9c9;
  height: 80%;
  margin-top: -1px;
}
.BarBomtomButtonBox {
  border: 1px solid #c9c9c9;
  height: 5%;
  margin-top: -1px;
  text-indent: 0.5rem;
  line-height: 2rem;
}

.printBoxForOne {
  width: 100%;
  height: 16rem;
  border: 1px solid #c9c9c9;
  overflow: hidden;
}
.printFuncBoxForOne {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 16rem;
  border: 1px solid #c9c9c9;
  align-items: center;
}

.barcodeContainer {
  text-align: center;
  overflow: hidden;
}

.printAreaReal {
  width: 24rem;
}
.funcAreaReal {
  width: calc(100% - 24rem);
}

.redButton {
  background-color: #f89898 !important;
}

.printAreaButton {
  width: 80%;
  overflow: hidden;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 5px;
  background-color: #409eff;
  color: #ffffff;
  margin-top: 0.5rem;
}
.printAreaButton:hover,
.topButton:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.topButton {
  width: 5rem;
  overflow: hidden;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 5px;
  background-color: #409eff;
  color: #ffffff;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.long {
  width: 8rem;
}

.totalMange {
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: auto;
}
.partNameContainer,
.companyNameContainer,
.docDateContainer,
.remarkContainer,
.salesPriceContainer {
  padding-left: 0.5rem;
  word-break: word-wrap;
  color: #747774;
}

.remarkContainer input {
  border: none;
  font-size: 0.8rem;
  color: #747774;
}

.docTypeContainer {
  text-align: center;
  word-break: word-wrap;
  font-style: 1rem;
  font-weight: 700;
}
.bigword {
  font-size: 1.5rem;
  font-weight: 700;
}
.funcBoxLayControl {
  width: 90%;
  margin-left: 5%;
  height: 98%;
  margin-top: 2%;
}
.commInput {
  width: 100%;
}

.CommodityContainer {
  width: 120%;
}
.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
}
.searchContentTr:hover {
  background-color: #f5f5f6;
}
.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.comminputDown {
  width: 75%;
}
.searchIndication {
  width: 25%;
}
.CommodityContainer {
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}
.commodityMangeLayer {
  margin-top: 1rem;
}
.timesForPrint {
  border-radius: 5px;
}
.warrentyRemark {
  width: 15rem;
}
.warrentyDayInput {
  width: 4rem;
  font-size: 1.2rem;
}
</style>