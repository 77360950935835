<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead ref="asInputHeadRef"></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">保存并出售</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, openAsList, checkDocCondition, transferToCode, getVmiCid, clearTableContent, transferToName } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { getDateTime, getUUId, isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'AsList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'AS'
    searchItemList.TableName = 'AsInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    const asInputHeadRef = ref(null)

    // getVmiCid().then(res => {
    //   if (res) {
    //     let b = transferToName(res, 'PartBCompanyId')
    //     if(isNaN(Number(b))){
    //       asInputHeadRef.value.searchItemList.documentContent.PartBCompanyId = b
    //     }
    //   }
    // })



    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {
        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      if (transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId') >= 20000000) {

        ElMessage('零件出售供应商不可为自建供应商')
        return;
      }
      for (let index in inputBodyRef.value.AddContentBody) {
        if (inputBodyRef.value.AddContentBody[index].CommodityName != '' && inputBodyRef.value.AddContentBody[index].OrderQty == '') {

          ElMessage(`第${+index + 1}行数量未填写`)
          return;
        }
        if (inputBodyRef.value.AddContentBody[index].CommodityName != '' && inputBodyRef.value.AddContentBody[index].OrderQty <= 0) {

          ElMessage(`第${+index + 1}行数量不可为0或小于0`)
          return;
        }
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const docHeadFirst = res;
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.SourceDocumentId = res
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
              item.DocumentId = res
            }
          }
          openAsList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, res).then(res => {
            if (res != 'pass') {

              ElMessage(res)
              deleteDocCenter(docHeadFirst).then(res => {
                return false;
              })

            }
            else {
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              const storage = window.localStorage;
              const user = store.state.loginInitializeData.userId
              let storeListFromWin = []
              const timeNow = getDateTime()
              if (JSON.parse(storage.getItem('BarStoreList' + user))) {
                storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
              }
              for (let item of inputBodyRef.value.AddContentBody) {
                if (item.CommodityName) {
                  for (let j = item.OrderQty - 1; j >= 0; j--) {
                    const newId2 = getUUId()
                    let addPrint2 = {
                      id: 'store' + newId2,
                      partName: item.CommodityName,
                      docType: '零件出售单',
                      companyName: store.state.loginInitializeData.companyNameAbbre,
                      docDate: timeNow,
                      remark: `${item.Remark}`,
                      barcode: docHeadFirst,
                    }
                    storeListFromWin.unshift(addPrint2)
                  }
                }
              }
              storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
              store.commit('updateBarcodeCount')

              clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
              emit('refreshMsg')
            }


          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList, asInputHeadRef
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButton {
  margin-right: 50rem;
}
</style>